
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import rulesService from '@/services/rules.service';
import AlertError from '@/components/alert-error/alert-error.component.vue';
import AlertDialog from '@/components/dialogs/alert-dialog.component .vue';
import DeleteDialog from '@/components/dialogs/delete-dialog.component.vue';
import userGroupService from '../../services/user-group.service';
import { Group } from '../../entities/group.entity';
import userRolesService from '../../services/user-roles.service';
import { User } from '../../entities/user.entity';
import userService from '../../services/user.service';
import { Role } from '../../../ProfileAccess/entities/role.entity';
import profilePermisionService from '../../../ProfileAccess/components/permissions/services/profile-permision.service';
import { Company } from '../../../Companies/entities/company.entity';
import companiesService from '../../../Companies/services/companies.service';
import { SellingPoint } from '../../../SellingPoints/entities/selling-point.entity';
import sellingPointsService from '../../../SellingPoints/services/selling-points.service';
import { GroupEnum } from '../../enum/group.enum';
import { Enterprise } from '../../../Enterprises/entities/enterprise.entity';
import enterpriseService from '../../../Enterprises/services/enterprise.service';
import clientService from '../../../../../Clients/services/client.service';
import { UserTypeRoleValueEnum } from './enums/user-roles-type.enum';
import statusService from '../../../Status/services/status.service';
import { Status } from '../../../Status/entities/status.entity';

@Component({
  components: { AlertError, DeleteDialog, AlertDialog },
  computed: {
    ...mapGetters({ userLogged: 'user' }),
  },
})
export default class UserCreateComponent extends Vue {
  public $refs: any;

  public user: User = new User();

  public userLogged!: any;

  public showDialogDelete: boolean = false;

  public notifications: any = {
    email: false,
    sms: false,
  };

  public groups: Group[] = [];

  public activeSidecheck: number = 0;

  public showDialogNewSecret: boolean = false;

  public showDialogDeleteSecret: boolean = false;

  public companies: Company[] = [];

  public sellingPoints: SellingPoint[] = [];

  public users: User[] = [];

  public enterprises: Enterprise[] = [];

  public roles: Role[] = [];

  public errors: any = [];

  public statusList: Status[] = [];

  public currentUserRole: Role = new Role();

  public status: any[] = [
    {
      id: 0,
      name: 'Inativo',
    },
    {
      id: 1,
      name: 'Ativo',
    },
  ];

  public rules: any = {
    required: rulesService.required,
    email: rulesService.email,
    sellingPointRequired: this.sellingPointRequired,
  };

  public get isSite() {
    const group: any = this.user.roleName;
    return group === GroupEnum.SITE;
  }

  public get isSuperAdm() {
    return this.user.group.isSuperAdministrator;
  }

  public get usersFiltered() {
    return this.user.id ? this.users.filter((u: User) => u.id !== this.user.id) : this.users;
  }

  public get isSameUserLogged() {
    return this.user.id === this.userLogged.id;
  }

  public get manager() {
    return UserTypeRoleValueEnum.MANAGER;
  }

  public clearAllFields(group: Group) {
    this.user.resetFieldsOnChangeGroup();
  }

  public sellingPointRequired(value: any) {
    if (!value) return true;
    return this.$permissionAccess.group.isPartner ? this.rules.required(value) : true;
  }

  public get hasError(): boolean {
    return Object.keys(this.errors).length > 0;
  }

  public get labelFormType() {
    return this.isEdit ? 'Editar' : 'Cadastrar';
  }

  public get isEdit() {
    const { id } = this.$route.params;
    return !!id;
  }

  public setShowDialogDelete(user: User) {
    this.showDialogDelete = true;
  }

  public closeDialogDelete() {
    this.showDialogDelete = false;
  }

  public closeDialogNewSecret() {
    this.showDialogNewSecret = false;
  }

  public closeDialogDeleteSecret() {
    this.showDialogDeleteSecret = false;
  }

  public setShowDialogNewSecret() {
    this.showDialogNewSecret = true;
  }

  public setShowDialogDeleteSecret() {
    this.showDialogDeleteSecret = true;
  }

  public generateNewSecret() {
    userService.generateSecret(this.user.id).then(async () => {
      await this.getUserById();
      this.$snackbar.open({
        text: 'Novo secret gerado com sucesso',
        color: 'success',
        buttonColor: 'white',
      });
      this.closeDialogNewSecret();
    });
  }

  public deleteSecret() {
    userService.deleteSecret(this.user.id).then(async () => {
      await this.getUserById();
      this.$snackbar.open({
        text: 'Secret excluido com sucesso',
        color: 'success',
        buttonColor: 'white',
      });
      this.closeDialogDeleteSecret();
    });
  }

  public deleteItem() {
    this.user
      .delete()
      .then(() => {
        this.$snackbar.open({
          text: 'Usuário excluido com sucesso',
          color: 'success',
          buttonColor: '#fff',
        });
        this.goToList();
      })
      .catch((err: any) => {
        this.$snackbar.open({
          text: err.response.data.message,
          color: 'danger',
          buttonColor: '#fff',
        });
        throw new Error(err);
      })
      .finally(() => {
        this.closeDialogDelete();
      });
  }

  public async getUserById() {
    const { id } = this.$route.params;
    const user = await userService.getUserById(id);
    this.user = new User(user);
    this.currentUserRole = new Role({ name: this.user.roleName });
    if (this.user.companyId) {
      this.getStatus();
    }
  }

  public async submit() {
    if (!this.$refs.form.validate()) return;
    if (this.isEdit) {
      this.edit();
      return;
    }
    this.save();
  }

  public async save() {
    this.user
      .save()
      .then(() => {
        this.errors = [];
        this.$snackbar.open({
          text: 'Usuário cadastrado com sucesso',
          color: 'success',
          buttonColor: '#fff',
        });
        this.$router.push({
          name: 'user-list',
        });
      })
      .catch((err: any) => {
        this.$snackbar.open({
          text: 'Erro ao cadastrar usuário',
          color: 'danger',
          buttonColor: '#fff',
        });
        if (err && err.response && err.response.data.errors) {
          this.errors = err.response.data.errors;
          return;
        }
        this.errors = { erro: [err.response.data.message] };
      });
  }

  public async edit() {
    this.user
      .edit()
      .then(() => {
        this.errors = [];
        this.$snackbar.open({
          text: 'Usuário alterado com sucesso',
          color: 'success',
          buttonColor: '#fff',
        });
        this.$router.push({
          name: 'user-list',
        });
      })
      .catch((err: any) => {
        if (err && err.response && err.response.data.errors) {
          this.errors = err.response.data.errors;
          return;
        }
        this.errors = { erro: [err.response.data.message] };
      });
  }

  public async goToList() {
    this.$router.push({
      name: 'user-list',
    });
  }

  public async getGroups() {
    const groups: Group[] = await userGroupService.getGroups();
    this.groups = groups.map(group => new Group(group));
  }

  public async getStatus() {
    this.statusList = await statusService.getStatusByCompanyId(this.user.companyId);
  }

  public async setCompanyId() {
    this.getRolesByCompanyId();
    this.getEnterprisesByCompanyId();
    this.getSellingPointsByCompanyId();
    this.getAllUsers();
    this.getStatus();
  }

  public async getAllUsers() {
    if (!this.user.companyId) return;
    const users = await userService.getUserNetworkAvailable({
      params: {
        company_id: this.user.companyId,
        user_id: this.user.id,
      },
    });
    this.users = users.map((it: any) => new User(it));
  }

  public async getRolesByCompanyId() {
    if (!this.user.companyId) return;
    const roles: Group[] = await profilePermisionService.getRolesByCompanyId(this.user.companyId);
    this.roles = roles.map(role => new Role(role));
  }

  public setCurrentRole(id: string) {
    const currentRole = this.roles.find((it: any) => it.id === id);

    this.currentUserRole = currentRole || new Role();
  }

  public async getCompanies() {
    const companies: Company[] = await companiesService.getAllCompanies();
    this.companies = companies.map(companie => new Company(companie));
  }

  public async getSellingPointsByCompanyId() {
    if (!this.user.companyId) return;
    const sellingPoints: Company[] = await sellingPointsService.getSellingPointByCompanyId(
      this.user.companyId,
    );
    this.sellingPoints = sellingPoints.map(sellingPoint => new SellingPoint(sellingPoint));
  }

  public async getEnterprisesByCompanyId() {
    if (!this.user.companyId) return;
    const enterprises: Company[] = await enterpriseService.getEnterpriseByCompanyId(
      this.user.companyId,
    );
    this.enterprises = enterprises.map(enterprise => new Enterprise(enterprise));
  }

  public async created() {
    this.$loader.open();
    if (this.isEdit) {
      await this.getUserById();
    }
    const promises = [
      this.getAllUsers(),
      this.getGroups(),
      this.getCompanies(),
      this.getRolesByCompanyId(),
      this.getSellingPointsByCompanyId(),
      this.getEnterprisesByCompanyId(),
      this.getStatus(),
    ];
    Promise.all(promises).then(() => {
      this.$loader.close();
    });
  }
}
